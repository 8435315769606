@import "reset.scss";
@import "fonts.css";

:root {
  --font-text: "Swis721 BT", sans-serif;
  --line-color: rgba(245, 245, 245, 0.5);
  --active-color: #c5d75d;
  --white-color: #f5f5f5;
  --white-color2: #ffffff;
  --white-colorO4: #f5f5f5d4;
  --grey-color: #9ea1a2;
  --dark_grey-color: #3e4346;
  --red-color: #ff7600;
  --blue-color: #00c4ff;
  --light-gray-color: #c3c3c3;
  --scroll-thumb-color: var(--active-color);
  --scroll-track-color: var(--dark_grey-color);

  --font14: 0.875rem;
  --font16: 1rem;
  --font18: 1.125rem;
  --font12: 0.75rem;
  --font10: 0.625rem;
}

html {
  font-size: 100% !important;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#dvs-plan {
  height: 100%;
  min-width: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: var(--font-text);
  color: var(--dark_grey-color);
  line-height: 1.14;
  font-stretch: condensed;
  letter-spacing: 0.3px;
  font-size: var(--font14);
  overflow: hidden;

  svg {
    overflow: hidden;
    vertical-align: middle;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    color: var(--text-color);
    &:visited {
      text-decoration: none;
      color: var(--text-color);
    }
    &:active {
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
    }
  }

  strong {
    font-weight: bold;
  }

  sup {
    vertical-align: top;
    font-size: 75%;
    position: static;
  }

  .hidden {
    display: none;
  }

  i {
    &.unlock-icon {
      content: url("../../assets/unlock.svg");
      width: 21px;
      height: 21px;
      display: inline-block;
    }
  }

  textarea,
  input {
    font-family: inherit;
  }

  p {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    letter-spacing: inherit;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    min-height: 90px;
    padding-left: 34px;
    padding-right: 40px;
    box-shadow: 0 3px 6px 0 rgba(5, 35, 52, 0.2);
    flex-wrap: wrap;
    position: relative;
    z-index: 5;
    .logo-wrapper {
      display: flex;
      gap: 8px;
      justify-content: flex-start;
      align-items: center;
      margin-top: 14px;
      margin-bottom: 8px;

      .logo {
        align-self: flex-start;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        .logo-content {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: unset;
          margin-left: 9px;

          svg {
            margin-top: 4px;
            height: 13.6px;
          }

          .plan-name {
            font-size: var(--font12);
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 3px;
            border-top: solid 1px var(--active-color);
            padding-top: 3px;
            padding-bottom: 3.5px;
            letter-spacing: inherit;
            line-height: normal;

            &.edit {
              padding-bottom: 3px;
              border-bottom: solid 0.5px var(--light-gray-color);
            }

            input {
              font-size: inherit;
              font-weight: normal;
              letter-spacing: inherit;
              color: inherit;
              width: 100%;
              padding: 0;
              text-align: left;
              border: none;
            }
            .tool {
              svg {
                margin: 0;
                height: auto;
              }
            }
          }
        }
      }
      .phone-number {
        margin-left: 18px;
        margin-bottom: 6px;

        a {
          font-weight: bold;
        }
      }
    }
    .right {
      display: inline-flex;
      align-items: center;

      button {
        background: none;
        min-width: unset;
        width: auto;
        height: auto;
        padding: 0;
        border: 0;
        font-size: inherit;
        font-weight: inherit;
        font-family: inherit;
        color: inherit;
        border: unset;
        border-radius: 0;
      }

      .download {
        width: 32px;
        height: 32px;
        border: 1px solid var(--red-color);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border-radius: 50%;
        font-size: var(--font18);
        font-weight: bold;
        color: var(--red-color);
        //margin-right: 15px;
      }
      .configure {
        width: 32px;
        height: 32px;
        border: 1px solid var(--blue-color);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border-radius: 50%;
        font-size: 1.7rem;
        padding-bottom: 3px;
        color: var(--blue-color);
        margin-right: 15px;
      }
      .tour-start {
        width: 32px;
        height: 32px;
        border: 1px solid #055105;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border-radius: 50%;
        font-size: 1.7rem;
        padding-bottom: 4px;
        color: var(--blue-color);
      }
      .user {
        letter-spacing: 0.7px;
        i {
          margin-right: 7px;
        }
      }
      .right-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 22px;
        &.save-status {
          font-size: var(--font12);
          min-width: 73px;
          #save-icon {
            .cls-1 {
              stroke: var(--dark_grey-color);
              transition: stroke 0.6s;
            }
            .cls-2 {
              transition: fill 0.2s;
            }
          }
          &.saving {
            #save-icon {
              .cls-1 {
                stroke: var(--active-color);
              }
              .cls-2 {
                fill: var(--active-color);
              }
            }
          }
          &.disconnect {
            color: var(--red-color);
          }
        }

        &.smart-garden {
          font-size: 0.875rem;
          font-weight: bold;
          font-stretch: condensed;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: 0.7px;
          text-align: center;

          .icon {
            position: relative;

            svg {
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%, calc(-100% + 45px));
            }
          }
        }

        span {
          margin-top: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .steps {
      text-align: center;
      position: relative;
      letter-spacing: 0.3px;
      .steps-container {
        position: absolute;
        top: 25px;
        z-index: 4;
        left: 50%;
        transform: translateX(-50%);
        background: none;
        height: 48px;
        box-sizing: border-box;
        &:after {
          height: 26px;
          width: 100%;
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          z-index: 1;
          background: #fff;
          border-radius: 0 0 22px 22px;
          box-shadow: 0 2px 6px 0 rgba(5, 35, 52, 0.25);
        }
        .steps-wrap {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          z-index: 2;
          background: #fff;
          border-radius: 0 0 22px 22px;
          padding: 5px 7px;
          height: 100%;
          box-sizing: border-box;
          .steps-control {
            width: 80px;
            min-width: 80px;
            padding-top: 9px;
            padding-bottom: 9px;
            &:first-child {
              margin-right: 24px;
            }
            &:last-child {
              margin-left: 24px;
            }
          }
          .steps-item {
            display: inline-flex;
            align-items: center;
            .arrow-right {
              margin: 0 10px;
            }
            .arrow-no {
              margin: 0 6px;
              cursor: pointer;
            }
            &:last-child .arrow-right {
              display: none;
            }
            &.active {
              .cls-1 {
                fill: var(--active-color) !important;
              }
            }
            &.active.error,
            &.error {
              .cls-1 {
                fill: var(--red-color) !important;
              }
            }
            &:disabled {
              .cls-1 {
                fill: var(--grey-color) !important;
              }
            }
            .step-icon {
              cursor: pointer;
            }
          }
        }
      }
      .steps-container.step-3 {
        left: calc(50% - 35px);
      }
      .tooltip-wrap.bottom {
        .tooltip-text {
          left: -10px;
          top: calc(100% + 18px);
        }
        .tooltip-arrow {
          left: 26px;
        }
      }
      &.to-check {
        color: var(--blue-color);
      }
    }
  }

  main {
    position: relative;
    margin: 0;
    height: 100%;
    .tools {
      position: absolute;
      top: 16px;
      left: 16px;
      border-radius: 32px;
      box-shadow: 2px 2px 6px 0 rgba(5, 35, 52, 0.5);
      background-image: linear-gradient(to bottom, #151517 0%, #052334 88%);
      width: 64px;
      padding: 28px 0;
      z-index: 2;
      max-height: calc(100% - 32px - 65px);
      box-sizing: border-box;
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-start;

      .tools-wrap {
        text-align: center;
        .tools-content {
          overflow: hidden;
        }
      }

      .tools-line {
        height: 0;
        border-bottom: 2px solid var(--line-color);
        margin: 12px 0;
        width: 100%;

        &.blurred {
          height: 8px;
          border-top: 2px solid var(--line-color);
          border-bottom: none;
          background: linear-gradient(to bottom, var(--red-color), #151517);
        }
      }

      .tools-block {
        position: relative;

        label {
          position: absolute;
          top: 0px;
          left: 15px;
          box-shadow: 2px 2px 6px 0 rgba(54, 54, 54, 0.5);
          background-image: linear-gradient(179deg, #151517 0%, #052334 88%);
          color: var(--line-color);
          padding: 1px 2px;
          border-radius: 3px;
          font-size: 10px;
          font-weight: bolder;
          font-style: normal;

          transform: translateY(-45%);
          text-align: left;
          max-width: calc(100% - 30px);
          box-sizing: border-box;
          width: min-content;

          &.mandatory {
            color: var(--red-color);
          }

          &.recommended {
            color: #96c255;
          }
        }

        .tools-block-elements {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: flex-start;

          &.single-element {
            justify-content: center;

            & > .tool.tools-item {
              margin: 5px 0 5px -5px;
            }
          }

          & > .tool.tools-item {
            margin: 5px 0px;
            flex-basis: 47%;
          }
        }
      }

      .tools-item {
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 18px;
        }
        &.tools-no_margin {
          margin-bottom: 0;
        }

        .cls-1 {
          fill: var(--white-color) !important;
        }

        &.disabled .cls-1 {
          fill: var(--grey-color) !important;
        }

        &:not(.disabled):hover .cls-1,
        &:not(.disabled).active .cls-1 {
          fill: var(--active-color) !important;
        }

        &.disabled .cls-4 {
          stroke: var(--grey-color);
        }

        &.disabled .cls-5 {
          stroke: var(--grey-color);
          fill: var(--grey-color);
        }

        &:not(.disabled):hover .cls-4,
        &:not(.disabled).active .cls-4 {
          stroke: var(--red-color);
        }

        &:not(.disabled):hover .cls-5,
        &:not(.disabled).active .cls-5 {
          stroke: var(--red-color);
          fill: var(--red-color);
        }
      }
      .popup-item {
        width: 36px;
        position: absolute;
        top: 70px;
      }
    }

    .tools.system-elements {
      width: 117px;

      .tools-block-elements {
        margin-left: 5%;
      }

      .tool.tools-item {
        margin: 3px 0px;
        width: 45%;
      }
    }

    .feedback {
      position: absolute;
      right: 18px;

      z-index: 4;

      &.small-icon {
        bottom: 10px;
        right: 10px;

        & > button.tool {
          position: relative;
          width: 48px;
          height: 48px;

          #FeedbackICON {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          &.active {
            --color: var(--active-color);
            --bg-color: linear-gradient(#151517, #052334);
            --text-color: #f5f5f5;
          }
        }
      }

      &.large-icon {
        bottom: 1px;

        & > button.tool {
          position: relative;
          width: 157.5px;
          height: 60px;

          &.active {
            --color: var(--active-color);
            --bg-color: linear-gradient(#151517, #052334);
            --text-color: #f5f5f5;
          }
        }
      }
    }
    .right-side {
      position: absolute;
      top: 16px;
      right: 16px;
      box-shadow: 2px 2px 6px 0 rgba(54, 54, 54, 0.5);
      background-image: linear-gradient(179deg, #151517 0%, #052334 88%);
      width: 172px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      line-height: 1.25;
      letter-spacing: 0.6px;
      text-align: left;
      color: var(--white-color);
      padding: 16px 0 24px 0;
      border-radius: 13px 13px 84px 84px;
      z-index: 2;
      max-height: calc(100% - 20px);
      box-sizing: border-box;
      &:after {
        content: "";
        display: block;
        height: 53px;
        min-height: 53px;
      }
      .scroll {
        &.pipeline-properties-wrap {
          form {
            min-height: auto;
            max-height: auto;
            height: auto;
          }
        }
      }
      form {
        min-height: 100%;
        max-height: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
      }
      .right-side-content {
        height: 100%;
      }
      .right-side-footer {
        position: relative;
        text-align: center;
        .right-side-icon {
          position: absolute;
          bottom: -53px;
          left: 0;
          right: 0;

          svg:hover {
            .cls-1 {
              fill: var(--active-color) !important;
            }
          }
        }
      }
      .properties {
        .properties-header {
          text-align: center;
          margin-bottom: 9px;
          .properties-element {
            letter-spacing: 0.7px;
            color: var(--white-color);
            padding: 0 16px;
            .element-icon {
              margin: 8px 0;
              .invalid-icon {
                cursor: pointer;
              }
            }
          }
        }
        .properties-title {
          text-transform: uppercase;
          letter-spacing: 0.7px;
          text-align: center;
          color: var(--white-color);
        }
        .properties-menu {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 7px 20px;
          border: solid 0.5px var(--grey-color);
          border-left: none;
          border-right: none;
          .tool:not(.inactive) {
            i {
              cursor: pointer;
            }
          }
        }
        .dropdown {
          font-size: var(--font12);
          width: 100%;
          .dropdown-item {
            color: var(--white-color);
          }
        }
        &:not(.disabled) {
          .properties-item {
            cursor: pointer;
            &.active {
              background: rgba(197, 215, 93, 0.2);
            }
          }

          .properties-item.disabled {
            cursor: default !important;
          }
        }
        .settings-item {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          align-items: stretch;
          justify-content: space-between;
          padding: 8px 16px;
          border-bottom: solid 1px var(--line-color);
          &.invalid {
            input[type="text"] {
              color: var(--red-color);
              border-color: var(--red-color);
            }
          }
          .setting-info {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
            .setting-icon-wrap {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .setting-value {
              flex-grow: 2;
              text-align: center;
            }
          }

          .setting-title {
            font-size: var(--font12);
            line-height: 1.25;
            letter-spacing: 0.6px;
          }

          .setting-control {
            flex-basis: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            .setting-control-item {
              cursor: pointer;
              &:not(:last-child) {
                margin-bottom: 8px;
              }
            }
          }

          &.disabled > .setting-control {
            .setting-control-item {
              cursor: default;
            }
          }
        }
        .system-properties-item {
          margin: 20px 16px 10px;
          font-size: var(--font12);
          display: flex;
          justify-content: space-between;
          align-items: center;
          &.item-wrap {
            flex-wrap: wrap;
          }
          & > div:first-child {
            flex-shrink: 2;
            white-space: pre-wrap;
          }
          .water-volume {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            span {
              &:first-child {
                word-break: break-word;
              }
              &:last-child {
                margin-left: 2px;
                white-space: nowrap;
              }
            }
          }
          .dropdown {
            margin-top: 5px;
          }
          input[type="text"] {
            width: 20px;
            margin: 0 2px;
          }
          .inform {
            margin-left: 5px;
            align-self: flex-end;
          }
          .toggle {
            margin-left: 12px;
          }
          .video-info {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            cursor: pointer;
            .video-text {
              margin-left: 13px;
              white-space: pre-wrap;
            }
          }
        }
        .properties-item {
          padding: 4px 12px 4px 17px;
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          box-sizing: border-box;
          font-size: var(--font12);
          i {
            margin-right: 8px;
          }
          &:not(:last-child) {
            margin-bottom: 5px;
          }
          &.properties-no_margin {
            margin-bottom: 0;
          }
        }
        .properties-line {
          height: 0;
          border: solid 1px var(--line-color);
        }
        .properties-footer {
          .properties-title {
            margin: 8px 0;
          }
          .properties-description {
            height: 57px;
            border: solid 1px var(--line-color);
            border-left: none;
            border-right: none;
            background-color: rgba(21, 21, 23, 0.5);
            resize: none;
            width: 100%;
            box-sizing: border-box;
            font-size: var(--font12);
            line-height: 1.25;
            letter-spacing: 0.6px;
            text-align: left;
            color: var(--white-color);
            padding: 7px 4px;
            &:focus {
              border: solid 1px rgba(197, 215, 93, 0.6);
              border-left: none;
              border-right: none;
            }
            &::-webkit-input-placeholder {
              color: var(--white-colorO4);
            }
            &::-moz-placeholder {
              color: var(--white-colorO4);
            }
            &:-ms-input-placeholder {
              color: var(--white-colorO4);
            }
            &::-ms-input-placeholder {
              color: var(--white-colorO4);
            }
            &::placeholder {
              color: var(--white-colorO4);
            }
          }
        }

        .pipeline-properties {
          padding: 0 12px;
          &.message {
            padding-top: 8px;
            padding-bottom: 11px;
            margin-top: 12px;
            letter-spacing: 0.35px;
            .title {
              font-weight: bold;
            }
            &.error {
              background-image: linear-gradient(
                to bottom,
                var(--red-color),
                rgba(128, 128, 128, 0)
              );
              background-size: 100% 50%;
              background-repeat: no-repeat;
              .error-img-wrap {
                margin-bottom: 45px;
                .error-img {
                  position: absolute;
                  bottom: 46px;
                  left: 0;
                  right: 0;
                  text-align: center;
                }
              }
            }

            &.success {
              background-image: linear-gradient(
                to bottom,
                var(--blue-color),
                rgba(128, 128, 128, 0)
              );
              background-repeat: no-repeat;
            }
            .pipeline-properties-item:first-child {
              margin-top: 0;
            }
          }
          .pipeline-name {
            margin-top: 10px;
            text-align: center;
            font-size: var(--font16);
            font-weight: bold;
          }
          .water-consumption {
            margin-top: 6px;
            .remaining {
              color: var(--blue-color);
              margin-top: 3px;
            }
            .total,
            .remaining {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: flex-end;
              & > div:first-child {
                width: 50%;
                text-align: right;
              }
              .item-text {
                margin-right: 2px;
                line-height: 0;
              }
            }
          }
          .pipeline-properties-item {
            margin-top: 12px;

            .pipeline-properties-row {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: flex-start;
            }
          }
          .item-icon {
            margin-right: auto;
            text-align: center;
            min-width: 62px;
            max-width: 62px;
          }
          .small-text {
            font-size: var(--font12);
            white-space: pre-wrap;
          }
          .item-text-wrap {
            margin-left: auto;
            margin-right: 0;
            margin-top: 8px;
            text-align: right;
            .text-wrap {
              margin-right: 3px;
            }
          }
          .item-text {
            font-size: var(--font16);
            font-weight: bold;
            margin-right: 2px;
          }
        }

        .pipeline-draw-properties {
          .small-text {
            white-space: pre;
          }
        }
      }
      .scale-tool {
        padding: 0 17px;
        line-height: 1.29;
        letter-spacing: 0.35px;
        .scale-header {
          text-transform: uppercase;
          font-size: var(--font16);
          letter-spacing: 0.8px;
          text-align: left;
          color: var(--active-color);
          border-bottom: solid 0.5px var(--active-color);
          padding-bottom: 7px;
          text-align: center;
        }
        .scale-container {
          text-align: justify;
        }
        b {
          font-weight: bold;
          color: var(--active-color);
        }
        p {
          margin-top: 7px;
        }
        input[type="text"] {
          font-weight: bold;
          letter-spacing: 0.35px;
          text-align: left;
          color: var(--active-color);
          background: none;
          border: none;
          border-bottom: solid 1px;
          width: 38px;
          padding: 0 5px;
          margin-right: 3px;
          text-align: center;
          &::-webkit-input-placeholder {
            color: var(--active-color);
          }
          &::-moz-placeholder {
            color: var(--active-color);
          }
          &:-ms-input-placeholder {
            color: var(--active-color);
          }
          &::-ms-input-placeholder {
            color: var(--active-color);
          }
          &::placeholder {
            color: var(--active-color);
          }
        }
        .scale-img {
          margin-top: -12px;
          text-align: right;
        }
      }
      .pipeline-properties-wrap {
        form:last-child:not(:first-child) .properties {
          padding-top: 4px;
        }
        .properties {
          .element-icon {
            position: relative;
            &:before {
              content: "";
              display: block;
              border-bottom: 2px solid var(--white-color);
              position: absolute;
              left: -16px;
              top: 18px;
              right: -16px;
              z-index: -2;
            }
          }
        }
      }
    }
    .scale {
      box-shadow: 2px 2px 6px 0 rgba(5, 35, 52, 0.5);
      background-image: linear-gradient(179deg, #151517 0%, #052334 88%);
      position: absolute;
      bottom: 29px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: stretch;
      padding: 8px 9px;
      height: 48px;
      border-radius: 48px;
      box-sizing: border-box;
      letter-spacing: 0.65px;
      color: var(--white-color);
      z-index: 2;
      .tooltip-wrap.top {
        position: static;
        .tooltip-text {
          bottom: calc(100% + 20px);
          max-width: 100%;
          margin-left: -9px;
          .tooltip-arrow {
            left: 27px;
          }
        }
      }
      .scale-item {
        cursor: pointer;
        /*&:not(.disabled):hover,*/
        &.active {
          .cls-1 {
            fill: var(--active-color) !important;
          }
          .cls {
            stroke: var(--active-color) !important;
          }
        }

        .hidden-pipe-count {
          position: absolute;
          top: 4px;
          left: 28px;
          background-color: #ff7600;
          border-radius: 32px;
          width: 15px;
          height: 15px;
          color: var(--white-color);
          font-size: var(--font10);
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
          line-height: 15px;
        }
      }
      .scale-line {
        width: 0;
        border-left: solid 2px #657d48;
        margin: 0 14px;
        display: inline-block;
      }
      .scale-info {
        display: flex;
        align-items: center;

        .scale-text-wrapper {
          padding: 0 18px;
          display: flex;
          flex-direction: row;
          align-items: stretch;
          justify-content: flex-end;
          width: 90px;
          &:empty {
            width: 21px;
          }

          overflow: hidden;

          .scale-text {
            max-width: 100%;
            position: relative;
            text-align: center;
            padding-bottom: 8px;

            &:after {
              content: "";
              border: solid 2px rgba(197, 215, 93, 0.7);
              border-top: none;
              height: 8px;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
            }
          }
        }

        .tool + .tool {
          margin-left: 32px;
        }
      }
    }
    .sensor-text {
      width: 13px;

      height: 15px;

      margin: 4.5px 0 4.5px 7.8px;

      font-family: var(--font-text);

      font-size: 12px;

      font-weight: bold;

      font-stretch: condensed;

      font-style: normal;

      line-height: 1.25;

      letter-spacing: 0.6px;

      text-align: left;

      color: #3e4346;
    }
    .sensor-coverage-legend {
      position: absolute;
      bottom: 90px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      max-width: calc(100% - 200px);
      width: max-content;
      opacity: 0.84;
      // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      // background-image: linear-gradient(to bottom, #151517, #052334);
      border-radius: 20px;
      padding: 5px 17px;
      font-size: var(--font16);
      // font-weight: bold;
      // color: var(--white-color);
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);
      .legend-item:not(:last-child) {
        margin-right: 24px;
      }
      i {
        width: 40px;
        min-width: 40px;
        max-width: 40px;
        height: 40px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 15px;
        background-color: #7826ff66;
        line-height: 10em;
      }
      .legend-line-two {
        font-weight: bold;
        display: inline-flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        margin: 5px auto;
      }
    }
    .coverage-legend-wrapper {
      position: absolute;
      bottom: 90px;
      width: max-content;
      max-width: calc(100% - 200px);
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);

      opacity: 0.84;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-image: linear-gradient(to bottom, #151517, #052334);
      border-radius: 20px;
      padding: 5px 17px;

      &.with-uniformity {
        border-radius: 48px;
        padding: 5px 34px;
      }

      font-size: var(--font16);
      font-weight: bold;
      color: var(--white-color);

      .coverage-legend {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }

      .distribution-uniformity-legend {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        column-gap: 8px;

        border-top: 2px solid grey;
        margin-top: 8px;
        padding-top: 8px;
        margin-bottom: 8px;

        .distribution-uniformity-title {
          margin-right: 18px;
        }

        .water-drops {
          display: flex;
          flex-direction: row;
          column-gap: 8px;
          margin-right: 14px;
          cursor: help;
        }
      }

      .legend-item:not(:last-child) {
        margin-right: 24px;
      }

      .legend-item {
        display: inline-flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        margin: 5px auto;
        i {
          width: 20px;
          min-width: 20px;
          max-width: 20px;
          height: 20px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 15px;
        }
      }
    }

    .irrigation-area-point:hover {
      fill: var(--dark_grey-color);
      opacity: 1;
    }

    .irrigation-plan {
      overflow: hidden;
      position: absolute;
      user-select: none;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      &.draggable {
        cursor: move;
      }

      .plan-container {
        position: relative;
        width: 100%;
        height: 100%;

        & > svg {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }

        & > svg.areas-layer:not(:last-child) {
          .rect,
          .circle {
            fill-opacity: 0.12;
          }
          text:not(.watermark) {
            display: none;
          }
        }

        .watermark {
          fill: var(--dark_grey-color);
          opacity: 0.6;
          text-transform: uppercase;

          .watermark-cls-1 {
            fill: var(--dark_grey-color);
          }

          .watermark-cls-0 {
            fill: #54b8c3;
          }
        }
      }
    }

    .window-popup {
      z-index: 5;
      top: 20px;
    }
    .window-blur {
      z-index: 4;
    }
    .recom-wrapper.window-popup {
      z-index: 3;
      & + .window-blur {
        z-index: 2;
      }
    }

    .coupon-popup-spacer {
      height: 450px;
    }

    .coupon-popup.window-popup {
      top: 0;
      padding-top: 210px;
      display: block;
      
      .window-popup-container {
        position: relative;
        width: 100%;
        min-height: 400px;
      }

      .coupon-popup-content {
        display: flex;
        min-height: 100%;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }

      .coupon-close {
        position: absolute;
        right: 20px;
        top: 15px;
        cursor: pointer;
        text-decoration: underline;
      }
      
      .coupon {
        margin: 16px;
        display: inline-flex;
        overflow: hidden;
        box-sizing: border-box;
        
        .coupon-image {
          position: relative;

          .coupon-price-wrapper {
            width: 80px;
            position: absolute;
            top: 90px;
            left: calc(28px - 4px);
            text-align: center;

            .coupon-price {
              font-size: 32px;
              font-weight: bold;
              position: relative;
            }

            .coupon-currency {
              top: 5px;
              position: absolute;
              font-size: 18px;
            }
          }
        }
        
        .coupon-content {
          margin-left: 20px;
          min-height: 160px;

          .coupon-title {
            position: relative;
            align-items: center;
            min-width: 160px;
            line-height: 24px;

            span {
              font-weight: bold;
            }

            a {
              position: absolute;
              right: 0;
              top: 0;
              cursor: pointer;
            }

          }

          .coupon-descr {
            margin-top: 4px;
            white-space: pre-wrap;
          }

          .bom-price {
            margin-top: 8px;
            font-weight: bold;
            text-transform: uppercase;
          }

          .submit {
            margin-top: 16px;
          }
        }
      }
      
      &::before {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000;
        opacity: 0.2;
      }

      .window-popup-header {
        color: var(--dark_grey-color);
        background-image: linear-gradient(to bottom, #c5d75d, var(--active-color));
      }
    }

    .high-z {
      z-index: 5;
    }

    .tour {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 4;
      background: rgba(0, 0, 0, 0.5);
      top: 0;
      left: 0;

      .element-highlight {
        box-sizing: border-box;
        position: absolute;

        border: solid;
        border-color: var(--red-color);

        &.rectangle {
          border-radius: 12px;
        }

        &.shield {
          border-radius: 13px 13px 84px 84px;
        }

        &.circle {
          border-radius: 50%;
        }
      }

      .animated-shadow {
        box-sizing: border-box;
        position: absolute;
        box-shadow: 0px 0px 12px 8px var(--red-color);
        animation: fadeInFromNone 2.5s ease-out infinite;

        &.rectangle {
          border-radius: 12px;
        }

        &.shield {
          border-radius: 13px 13px 84px 84px;
        }

        &.circle {
          border-radius: 50%;
        }
      }

      @keyframes fadeInFromNone {
        0% {
          display: none;
          opacity: 0;
        }

        50% {
          display: block;
          opacity: 1;
        }

        100% {
          display: none;
          opacity: 0;
        }
      }
    }

    .tour-info {
      box-sizing: border-box;
      position: absolute;
      bottom: 96px;
      left: 136px;
      right: 136px;
      margin: 0 16px;
      padding: 16px 24px;
      z-index: 4;

      border-radius: 16px;
      box-shadow: 2px 2px 6px 0 rgba(5, 35, 52, 0.5);
      color: var(--white-color);
      background-image: linear-gradient(to bottom, #151517 0%, #052334 88%);
      opacity: 80%;

      &.left {
        left: 0;
        right: 50%;
      }

      &.right {
        left: 50%;
        right: 0;
      }

      .tour-info-title {
        text-transform: capitalize;
        font-weight: bold;
        margin-bottom: 8px;
        color: var(--active-color);
      }

      .tour-info-description {
        white-space: pre-wrap;
        margin-bottom: 16px;

        .link {
          font-weight: bold;
          color: var(--active-color);

          &:hover {
            text-decoration: underline;
          }
        }

        .tour-info-steps-icon-list {
          line-height: 2;
          svg {
            padding-bottom: 2px;
          }
        }
      }

      .tour-info-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .step-controls {
          button {
            min-width: 48px;
          }
          .with-margin {
            margin-right: 16px;
          }
        }
      }
    }
  }

  footer {
    background-color: var(--active-color);
    padding: 11px 34px;
    text-transform: uppercase;
    font-size: var(--font12);
    letter-spacing: 0.6px;
    ul {
      justify-content: flex-start;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      li {
        &:not(:last-child):after {
          content: "|";
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }

  .plan-assessment-control {
    position: absolute;
    left: 64px;
    bottom: 60px;
    z-index: 4;
    button {
      padding: 16px;
      border-radius: 32px;
      background-color: var(--active-color);
      color: var(--dark_grey-color);
    }
  }

  .plan-assessment {
    position: absolute;
    left: 96px;
    bottom: 136px;
    min-width: 210px;
    min-height: 145px;
    max-height: calc(100% - 360px);
    background-image: linear-gradient(179deg, #151517 0%, #052334 88%);
    border-radius: 32px;
    padding: 16px;

    z-index: 5;

    display: flex;
    flex-direction: column;

    letter-spacing: 0.6px;
    text-align: left;
    color: var(--white-color);

    &.extend {
      min-height: 260px;
    }

    .divider {
      height: 1px;
      background-color: gray;
      width: 100%;
      margin: 8px 0;

      &.narrow {
        margin: 4px 0;
      }
    }

    .assessment {
      display: flex;
      flex-direction: column;

      padding-top: 8px;
      .dropdown {
        width: 85%;
        margin-top: 6px;

        .dropdown-item {
          color: var(--white-color);
        }
      }
    }

    .annotations-wrapper {
      display: flex;
      flex-direction: column;

      min-width: 0;
      min-height: 101px;
    }

    .annotations-container {
      margin-top: 6px;
      padding-right: 6px;
      overflow-y: auto;

      .annotations {
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        & > button {
          padding: 8px;
          min-width: 0;
        }

        & > button:focus {
          --active-color: var(--red-color);
          background-color: var(--red-color);
          color: var(--dark_grey-color);
        }

        &.used > button {
          --active-color: var(--red-color);
          color: var(--dark_grey-color);
          background-color: var(--red-color);
        }

        &.used > button:focus {
          --active-color: #c5d75d;
          background-color: #151517;
          color: var(--white-color);
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      row-gap: 6px;
    }
  }
  .visual-arrow {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 130px;
    bottom: 108px;
    z-index: 4;
  }

  * {
    -webkit-overflow-scrolling: touch;
    scrollbar-color: var(--scroll-thumb-color) var(--scroll-track-color) !important;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      height: 10%;
      width: 4px;
      border-radius: 4px;
      background-color: var(--scroll-thumb-color);
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: var(--scroll-track-color);
    }
    &::-webkit-scrollbar-button {
      height: 3px;
      width: 3px;
      background-color: none;
    }
    &::-webkit-scrollbar-corner {
      background: none;
    }
  }

  .scroll {
    overflow: auto;
  }

  .dropdown {
    width: 153px;
    letter-spacing: 0.7px;
    color: var(--white-color);
    position: relative;
    .dropdown-value {
      width: 100%;
      height: 32px;
      border-radius: 16px;
      border: solid 0.7px var(--white-color);
      background-color: #151517;
      padding: 8px 6px 8px 14px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      cursor: pointer;
      overflow: hidden;
      .dropdown-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 83%;
        overflow: hidden;
      }
    }
    .dropdown-value.inactive {
      cursor: default !important;
    }
    .dropdown-container {
      position: fixed;
      width: 100%;
      border-radius: 16px;
      border: solid 1px var(--active-color);
      background-image: linear-gradient(to bottom, #151517, #052334);
      letter-spacing: 0.35px;
      padding: 10px 3px;
      box-sizing: border-box;
      z-index: 10;
      visibility: hidden;
      .dropdown-item {
        cursor: pointer;
        height: 48px;
        display: flex;
        align-items: center;
        padding: 8px;
        box-sizing: border-box;
        color: var(--dark_grey-color);
        &.active {
          color: var(--dark_grey-color);
          background-color: var(--active-color);
          &:hover {
            font-weight: bold;
          }
        }
        &:not(.active):hover {
          color: var(--active-color);
          font-weight: bold;
        }
      }
    }
  }

  .toggle {
    width: 61.5px;
    min-width: 61.5px;
    max-width: 61.5px;
    height: 30px;
    border-radius: 20px;
    border: solid 1px var(--white-color);
    background-color: #151517;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 3px 3px;
    cursor: pointer;
    justify-content: flex-start;
    .toggle-button {
      width: 24px;
      height: 24px;
      background-color: var(--white-color);
      border-radius: 50%;
    }
    &.selected {
      justify-content: flex-end;
      border: solid 1px var(--active-color);
      .toggle-button {
        background-color: var(--active-color);
      }
    }
  }

  .inform {
    cursor: pointer;
  }

  input[type="text"] {
    color: var(--active-color);
    letter-spacing: 0.8px;
    font: inherit;
    font-weight: bold;
    border: none;
    background: none;
    border-bottom: 2px solid;
    padding: 0 5px;
    width: 30px;
    display: inline-block;
    text-align: center;
    box-sizing: content-box;
  }

  .slider {
    position: relative;
    .slider-wrap {
      border-radius: 16px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-image: linear-gradient(to bottom, #151517, #052334);
      right: 0;
      top: 0;
      z-index: 10;
      display: flex;
      align-items: stretch;
      box-sizing: border-box;
      .slider-button-wrap {
        position: relative;
        margin-left: 3px;
        &:before {
          content: "";
          top: 0;
          bottom: 0;
          left: 0;
          border: solid 2px #ffffff;
          position: absolute;
        }
        .slider-button {
          width: 16px;
          height: 16px;
          border: solid 2px #151517;
          background-color: var(--active-color);
          border-radius: 50%;
          position: absolute;
          left: -8px;
          box-sizing: content-box;
          .slider-value {
            position: absolute;
            left: 18px;
            width: 22px;
            text-align: center;
            letter-spacing: 0.35px;
            color: var(--white-color);
          }
        }
      }
    }
  }

  .checkbox-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;

    .checkbox {
      cursor: pointer;
      width: 24px;
      min-width: 24px;
      max-width: 24px;
      height: 24px;
      min-height: 24px;
      max-height: 24px;
      border-radius: 6px;
      border: solid 1px var(--white-color);
      background-color: #151517;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding-top: 1px;
      padding-left: 1px;
      &.checked {
        border: solid 1px var(--active-color);
      }
      &.disabled {
        border: solid 1px var(--grey-color);
        background-color: var(--grey-color);
      }
    }
    label.checkbox {
      margin-right: 10px;
    }

    &.reversed {
      flex-direction: row-reverse;
      label.checkbox {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }

  .radio-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;

    .radio {
      cursor: pointer;
      width: 24px;
      min-width: 24px;
      max-width: 24px;

      height: 24px;
      min-height: 24px;
      max-height: 24px;

      border-radius: 50%;
      border: solid 1px var(--white-color);
      background-color: #151517;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      position: relative;

      &.checked {
        border: solid 1px var(--active-color);
        .circle {
          left: 50%;
          top: 50%;
          position: absolute;
          width: 14px;
          height: 14px;
          border-radius: 20px;
          background-color: #c5d75d;
          background-color: var(--active-color);
          transform: translate(-50%, -50%);
        }
      }
      &.disabled {
        border-color: var(--grey-color);
        background-color: var(--grey-color);
      }
    }

    label.radio {
      margin-right: 10px;
    }
  }

  button {
    border-radius: 16px;
    border: solid 1px var(--active-color);
    background-color: #151517;
    box-sizing: border-box;
    letter-spacing: 0.35px;
    text-align: center;
    color: var(--white-color);
    min-width: 147px;
    padding: 7px;
    font: inherit;
    cursor: pointer;

    &:visited {
      color: var(--white-color);
    }
    &:active {
      background-color: var(--active-color);
      color: var(--dark_grey-color);
    }
    &:disabled,
    &.disabled {
      border: solid 1px var(--grey-color);
      background-color: var(--grey-color);
    }
    &.button:not(.disabled):not(:disabled):focus {
      outline: none;
      background-color: var(--active-color);
      color: var(--dark_grey-color);
    }
    &:not(.button):not(.disabled):not(:disabled):not(.active):focus {
      i {
        outline: dotted;
        outline-width: 1px;
        outline-offset: 0px;
      }
    }
  }

  .button-file {
    .error {
      color: var(--red-color);
      display: inline-block;
      margin-top: 5px;
    }
    .hint {
      font-style: italic;
      font-size: var(--font12);
    }
  }

  button.tool {
    border: none;
    border-radius: none;
    border-radius: 0;
    background: none;
    width: auto;
    height: auto;
    padding: 0;
    min-width: auto;
    max-width: initial;
    min-height: auto;
    max-height: initial;

    &.inactive {
      cursor: default;
    }
  }

  .tooltip-wrap {
    opacity: 0;
    visibility: hidden;
    transition: opacity 500ms;
    .tooltip-content {
      display: flex;
    }
    position: absolute;
    z-index: 5;
    .tooltip-text {
      box-sizing: border-box;
      color: var(--dark_grey-color);
      position: relative;
      max-width: 312px;
      width: max-content;
      background: #e6eacb;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px var(--dark_grey-color);
      border-radius: 8px;
      padding: 9px 15px;
      line-height: 1.14;
      font-size: var(--font14);
      letter-spacing: 0.35px;
      text-align: left;
      white-space: pre-wrap;
      b {
        font-weight: bold;
      }
      i {
        font-style: italic;
      }
    }
    .tooltip-arrow {
      white-space: normal;
      position: relative;
      width: 18px;
      height: 18px;
      left: -9px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-10px);
        left: -7px;
        border: 9px solid transparent;
        border-right: 16px solid var(--dark_grey-color);
      }
    }
    &.right {
      .tooltip-content {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-left: 13px;
      }

      .tooltip-text {
        order: 2;
      }
      .tooltip-arrow {
        left: 0;
      }
    }
    &.top {
      .tooltip-content {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 10px;
      }
      .tooltip-text {
        margin-left: -20px;
      }
      .tooltip-arrow {
        transform: rotate(-90deg);
        margin-left: 2px;
      }
    }
    &.bottom {
      .tooltip-content {
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 3px;
      }
      .tooltip-text {
        margin-left: -20px;
        order: 2;
      }
      .tooltip-arrow {
        transform: rotate(90deg);
      }
    }
  }

  .dvs-progress-bar {
    height: 16px;
    border-radius: 8px;
    border: solid 0.7px var(--white-color);
    background-color: #052334;
    position: relative;
    overflow: hidden;
    padding: 1.6px;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    &:before {
      content: "";
      display: flex;
      position: absolute;
      left: 50%;
      top: 3px;
      bottom: 3px;
      width: 1px;
      background: var(--white-color);
    }
    .dvs-progress {
      background-color: var(--blue-color);
    }
    &.error {
      .dvs-progress {
        background-color: var(--red-color);
      }
    }
  }

  .popup {
    position: relative;
    z-index: -1;
    opacity: 0;
    transition: opacity 100ms;
    .popup-container {
      line-height: 1.19;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-image: linear-gradient(to bottom, #151517, #052334);
      border-radius: 20px;
      padding: 17.5px;
      box-sizing: border-box;
      position: absolute;
      z-index: -1;
      right: auto;
      bottom: 0;
      letter-spacing: 0.35px;
      text-align: left;
      .popup-close {
        position: absolute;
        top: 17px;
        right: 10px;
        font-size: var(--font18);
        line-height: 0;
        cursor: pointer;
        color: var(--white-color);
        user-select: none;
      }
      .popup-title {
        font-size: var(--font16);
        line-height: 1.19;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        color: var(--active-color);
        margin-bottom: 12px;
        padding-bottom: 10px;
        border-bottom: solid 1px var(--active-color);
      }
      .text-popup {
        color: var(--active-color);
        width: 250px;
      }
    }

    &.top-left {
      .popup-container {
        min-height: 110px;
      }
      .popup-arrow {
        position: absolute;
        width: 30px;
        height: 20px;
        right: -30px;
        top: 55px;
      }
    }
    &.bottom {
      .popup-container {
        top: 18px;
        display: table;
        min-height: fit-content;
      }
      .popup-arrow {
        width: 17px;
        height: 17px;
        top: -17px;
        position: absolute;
        left: 50%;
        transform: translateX(-5px);
      }
    }
    &.left {
      .popup-container {
        right: 140px;
        .popup-arrow {
          width: 120px;
          height: 45px;
          position: absolute;
          bottom: -19px;
          left: calc(100% - 26px);
        }
      }
    }
    &.top {
      .popup-container {
        bottom: 0;
        .popup-arrow {
          width: 10px;
          height: 20px;
          position: absolute;
          bottom: -20px;
          left: 50%;
          transform: translateX(-5px);
        }
      }
    }
  }

  .start-draw.popup {
    .popup-container {
      left: calc(100% + 53px);
      right: auto;
      top: 0;
      bottom: auto;
    }
    .popup-arrow {
      width: 20px;
      height: 20px;
      position: absolute;
      left: -19px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .error.popup {
    .popup-container {
      left: 50%;
      transform: translateX(-50%);
      width: 334px;
      padding-right: 30px;
    }
    .error-wrap {
      max-height: 200px;

      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: flex-start;

      .error-popup-title,
      .error-content {
        display: grid;
        grid-template-columns: 32px auto;
        grid-gap: 16px;

        .icon {
          margin: 0 auto;
          svg {
            width: 30px;
            height: auto;
          }
        }
      }

      .error-content {
        color: var(--white-color);
        font-size: var(--font14);
        line-height: 16px;
        white-space: pre-wrap;

        .error-tree-key {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin: 0 auto;
        }

        .error-message {
          margin-top: 10px;
        }

        .error-title {
          white-space: pre-wrap;
          font-size: var(--font16);
          color: var(--red-color);
          line-height: 19px;
          letter-spacing: 0.8px;
        }

        ul li {
          list-style: decimal;
          list-style-position: inside;

          & + li {
            margin-top: 8px;
          }
        }
      }
    }
  }

  .pipe-view-popup.popup {
    color: var(--white-color);
    cursor: initial;
    font-size: var(--font12);

    .fixed-pipes {
      display: flex;
      flex-direction: column;

      .label {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        min-width: 0;
        padding: 0 12px 12px 12px;

        text-align: left;

        svg {
          flex-shrink: 0;
          margin-right: 6px;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }

      .label > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-break: break-word;
      }
    }

    .divider {
      height: 1px;
      background-color: #9ea1a2;
      opacity: 0.5;
      margin: 10px 0 10px;
    }

    .show-hide-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      min-width: 0;
      padding: 0 12px 0 12px;
      margin-bottom: 18px;

      cursor: pointer;

      svg {
        flex-shrink: 0;
        margin-right: 6px;
      }

      div {
        text-align: left;
      }
    }

    .show-hide-button > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: break-spaces;
      word-break: break-word;
    }

    .pipe-item-wrap {
      max-height: 176px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      row-gap: 16px;
      column-gap: 12px;

      padding: 0 18px 0 12px;

      &::-webkit-scrollbar {
        padding-left: 8px;
      }
    }

    .pipe-item-wrap > div:nth-child(2n) {
      margin-right: 0;
    }

    .popup-container {
      bottom: 32px;
      left: 50%;
      transform: translateX(-50%);
      width: 121px;
      padding: 18px 0 18px 0;
      box-sizing: border-box;
      text-align: center;

      .popup-close {
        right: 18px;
        transform: translateX(50%);
      }
    }

    .pipe-item {
      svg {
        cursor: pointer;
      }
    }
  }

  .properties-popup.popup {
    position: absolute;
    top: 0;
    left: 0;
    .popup-container {
      right: 35px;
      top: 35px;
      bottom: auto;
      .properties-popup-content {
        width: 300px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        svg {
          min-width: 32px;
          margin-right: 10px;
        }
        .properties-popup-text {
          flex-grow: 2;
          white-space: pre-wrap;
          .popup-title {
            color: var(--red-color);
            &:after {
              display: none;
            }
          }
          .popup-more {
            text-align: right;
            a {
              color: var(--active-color);
              font-weight: bold;
              &:before {
                content: "";
                display: inline-block;
                width: 12px;
                height: 12px;
                background: url("../../assets/arrow-before-link.svg") 0 0
                  repeat-x;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }

  .sensor-popup {
    z-index: 4;

    .popup-container {
      top: 40px;
      left: 50%;
      transform: translateX(-50%);

      width: 562px;
      height: 120px;
      .sensor-popup-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        svg {
          min-width: 32px;
          margin-right: 10px;
        }
        .sensor-popup-text {
          flex-grow: 2;
          white-space: pre-wrap;
          color: #f5f5f5;
          font-family: var(--font-text);

          font-size: 14px;

          font-weight: normal;

          font-stretch: condensed;

          font-style: normal;

          line-height: 1.14;

          letter-spacing: 0.35px;

          text-align: left;
          span {
            font-weight: bold;
          }
        }
      }
    }
  }

  .feedback-popup.popup {
    z-index: 4;
    top: 50%;
    left: 50%;

    .popup-container {
      width: 536px;
      max-width: 100%;

      top: 50%;
      left: auto;
      bottom: auto;
      right: auto;

      transform: translate(-50%, -50%);

      padding: 0;
      border: 1px solid var(--active-color);

      font-size: 16px;
      line-height: 1.31;
      letter-spacing: normal;
      text-align: center;
      color: var(--white-color);
      white-space: normal;
      font-weight: normal;
      font-stretch: condensed;

      .feedback-container {
        padding: 0 17.5px 17.5px 17.5px;
      }
      .popup-title {
        padding: 17.5px 17.5px 10px 17.5px;
        text-align: center;
        border-color: var(--white-color);
      }
      .row + .row {
        margin-top: 16px;
      }

      .text {
        font-weight: bold;
      }
      .rate {
        margin-top: 8px;

        button.tool {
          padding: 0 4px;
          &.selected,
          &:hover {
            --color: var(--active-color);
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
      .category {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        button {
          padding: 8px;
          background-color: #151517;

          font-size: 14px;
          line-height: 1.14;
          box-shadow: 0 0 0 1px var(--active-color);
          text-overflow: ellipsis;
          overflow: hidden;

          &.selected,
          &:hover {
            background-color: var(--active-color);
            color: #151517;
          }

          &:hover {
            opacity: 0.95;
          }
        }
      }
      .description {
        textarea {
          border-radius: 8px;
          border: solid 1px var(--active-color);
          padding: 8px;
          width: 100%;
          background: unset;
          height: 56px;
          resize: none;
          box-sizing: border-box;

          font-size: 14px;
          line-height: 1.14;
          color: var(--white-color);

          &::placeholder {
            color: var(--grey-color);
          }
        }
      }
      .controls {
        padding-top: 4px;
        justify-content: center;
        display: flex;
        button {
          &,
          &:active {
            background-color: #151517;
            color: var(--white-color);
          }
        }
      }
      .success {
        .success-title {
          font-size: 3.0625rem;
          color: var(--active-color);
          text-align: center;
          svg {
            vertical-align: initial;
            --color: var(--active-color);
          }
        }
        .success-description {
          color: var(--white-color);
          font-size: 1rem;
          font-weight: bold;
          padding: 0 54px;
          margin-top: 14px;
        }
        & + .controls {
          margin-top: 37px;
        }
      }
      .popup-arrow {
        position: absolute;
        transform: translate(50%, 100%) rotate(315deg);
        right: -9px;
        bottom: 17px;
      }
    }
  }

  .opacity-popup.popup {
    .popup-container {
      width: 95px;
      bottom: 32px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .coverage-info.popup {
    .popup-container {
      width: 250px;
      bottom: 32px;
      left: 50%;
      transform: translateX(-50%);
      color: var(--active-color);
      white-space: pre-wrap;
    }
  }

  .window-blur {
    background-color: rgba(245, 245, 245, 0.5);
    backdrop-filter: blur(1.7px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
  }
  .window-popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    .window-popup-container {
      border-radius: 24px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      box-sizing: border-box;
      overflow: hidden;
      line-height: 1.3;
      letter-spacing: 0.8px;
      z-index: 1;
    }
    .window-popup-header {
      background-image: linear-gradient(to bottom, #151517, #052334);
      text-align: left;
      color: var(--active-color);
      padding: 15px 21px;
    }
    .window-popup-content {
      padding: 21px 24px 18px 24px;
    }

    .window-popup-footer {
      background-image: linear-gradient(to top, #151517, #052334);
      text-align: left;
      color: var(--active-color);
      padding: 15px 21px;
    }
    .window-popup-close {
      font-size: 26px;
      position: absolute;
      right: 6px;
      top: 4px;
      line-height: unset;
      cursor: pointer;
    }
    .plan {
      width: 414px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;

      .window-popup-content {
        min-height: 280px;
        padding: 11px 30px 24px 30px;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;

        .row + .row {
          margin-top: 16px;
        }

        .title {
          display: inline-flex;
          align-items: center;
          font-weight: bold;
          white-space: pre-wrap;
          font-size: var(--font16);
        }
        .plan-name {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: stretch;
          input {
            width: 100%;
            text-align: left;
            border-bottom: solid 1.5px var(--light-gray-color);
            color: inherit;
            margin-left: 5px;
            font-weight: normal;
            letter-spacing: 0.3px;
            font-size: var(--font16);
          }
        }
        .plan-type {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: stretch;
          text-align: center;
          .plan-load-or {
            position: relative;

            .line-wrap {
              position: absolute;
              z-index: 1;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;

              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: stretch;

              .line {
                height: 100%;
                width: 1px;
                border-left: var(--border);
              }
            }
            svg {
              position: relative;
              z-index: 2;
            }
          }
          .plan-load-type {
            flex-basis: 50%;
            &:first-child {
              .title {
                justify-content: flex-start;
              }
            }
            .title {
              height: 54px;
              width: 100%;
              text-align: center;
              justify-content: center;
            }
            button {
              margin-top: 21px;
              width: 100%;
            }
          }
        }
        .plan-terms {
          font-size: var(--font16);
          letter-spacing: normal;
          line-height: 1.19;
          text-align: center;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          a {
            font-weight: bold;
            color: var(--active-color);
            margin: 0 3px;
            &:hover {
              text-decoration: underline;
            }
          }
          .checkbox {
            margin-right: 12px;
          }
        }
        .sprinkler-type {
          .sprinkler-type-title {
            font-size: 1rem;
            font-weight: bold;
            font-stretch: condensed;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: 0.8px;
            color: var(--active-color);
            text-align: center;
            margin-bottom: 12px;
          }
          .plan-load-or {
            position: relative;
            padding: 16px 0;

            .line-wrap {
              position: relative;
              z-index: 1;

              display: flex;
              align-items: center;
              justify-content: stretch;

              .line {
                height: 1px;
                width: 100%;
                border-top: var(--border);
              }
            }
            svg {
              z-index: 2;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translate(0, -50%);
              flex-shrink: 0;
            }
          }
          button {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: flex-start;

            padding: 0;
            border: unset;
            border-radius: 0;
            background: none;
            color: var(--dark_grey-color);

            .icon {
              flex-shrink: 0;
              width: 84px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
            }

            .info {
              flex: auto;
              padding-left: 16px;
              text-align: left;
              white-space: pre-line;
              .title {
                font-size: 1rem;
                font-weight: bold;
              }
            }

            &.active {
              --bg-color: var(--active-color);
              --color: #fff;
            }
          }
        }
      }

      .window-popup-controls {
        padding-top: 20px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        button {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;

          width: auto;
          min-width: unset;
          background: none;
          padding: 0;
          border-radius: 0;
          border: unset;

          font-size: 0.875rem;
          font-weight: normal;
          font-stretch: condensed;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: 0.35px;
          text-align: center;
          color: var(--dark_grey-color);

          & > span {
            padding: 0 12px;
          }
        }
      }
    }
    .recom {
      box-sizing: border-box;
      width: 95%;
      max-width: 900px;
      min-width: 720px;
      position: absolute;
      top: 25px;
      bottom: 25px;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;
      overflow: hidden;

      .window-popup-header,
      .window-popup-content,
      .window-popup-footer {
        width: 100%;
        box-sizing: border-box;
      }
      .window-popup-header {
        position: relative;
        flex: 0 0;
        padding-bottom: 35px;
        font-size: var(--font14);
        text-align: center;
        .row {
          margin-left: 7px;
          margin-right: 0;
          align-items: center;
          .column {
            white-space: pre-wrap;
          }
        }
      }

      .recom-group {
        font-size: var(--font16);
        font-weight: bold;
        letter-spacing: 0.7px;
        margin-top: 16px;
        text-transform: uppercase;
      }

      .row {
        display: grid;
        align-items: center;
        grid-gap: 14px 10px;

        grid-template-columns: 30px 75px auto 104px 135px 85px 85px 71px;
        box-sizing: border-box;
      }

      .other-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: var(--dark_grey-color);
        text-align: left;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 22px;
        z-index: 1;
        font-size: var(--font12);
        background: var(--active-color);
        border-radius: 0 13px 0 0;
        li {
          flex-basis: 50%;
          border-radius: 0 13px 0 0;
          padding: 4px 22px;
          position: relative;
          height: 22px;
          box-shadow: inset 0 -5px 5px -4px rgba(0, 0, 0, 0.3);
          box-sizing: border-box;
          button {
            color: inherit !important;
            font-weight: bold !important;
            width: 100%;
            text-align: left;
            text-transform: uppercase;
          }
          &.active {
            border-radius: 13px 13px 0 0;
            background: #fff;
            z-index: 2;
            box-shadow: 6px 0px 5px -4px rgba(0, 0, 0, 0.3),
              -6px 0px 5px -4px rgba(0, 0, 0, 0.3);
            &:last-child {
              box-shadow: -6px 0px 5px -4px rgba(0, 0, 0, 0.3);
            }
            &:first-child {
              border-radius: 0 13px 0 0;
              box-shadow: 6px 0px 5px -4px rgba(0, 0, 0, 0.3);
            }
          }
        }
      }
      .window-popup-content {
        display: flex;
        flex-direction: column;

        flex: auto;
        overflow: hidden;
        padding: 3px 0;
        position: relative;
        .recom-content {
          flex: auto;
          padding: 0 7px 0 22px;
          overflow-x: hidden;

          .smart-garden {
            --border-color: #7826ff;
            position: relative;

            .row {
              border: solid 1px var(--border-color);
              padding-bottom: 16px;
              .separate {
                grid-column: 2/-1;
                padding-right: 20px;
                &:before {
                  content: "";
                  display: block;
                  border-top: solid 1px var(--border-color);
                  opacity: 0.5;
                }
              }
            }

            .buy {
              position: absolute;
              bottom: 0;
              right: 20px;
              transform: translateY(50%);
              z-index: 2;

              padding-left: 38px;

              svg {
                height: 100%;
                width: auto;
                position: absolute;

                top: 50%;
                left: 0;
                transform: translate(-2px, -50%);
              }
            }
          }

          .row {
            border: solid 1px var(--active-color);
            margin: 4px 7px 4px 0;
            padding: 7px 5px;
            box-sizing: border-box;

            a.column .name {
              text-decoration: underline;
              &:hover {
                text-decoration: none;
              }
            }

            &.none {
              border-color: var(--grey-color);
              .column {
                opacity: 0.4;

                &.column-with-dropdown {
                  opacity: 1;
                  .dropdown .dropdown-value {
                    opacity: 0.4;
                  }
                }
              }
            }

            .dropdown {
              width: 89px;
              color: inherit;
              font-size: inherit;
              .dropdown-value {
                background: #fff;
                border-color: var(--grey-color);
              }
              .dropdown-container {
                background: #fff;
              }
              #Shevron_Down .cls-1 {
                stroke: var(--grey-color);
              }
            }

            .column {
              align-self: center;

              &.title-column {
                font-size: var(--font12);
                letter-spacing: 0.6px;
                .name {
                  font-weight: bold;
                  font-size: var(--font14);
                  letter-spacing: 0.7px;
                  margin-bottom: 2px;
                }
                .description {
                  strong {
                    color: var(--strong-color);
                    font-size: 0.875rem;
                  }
                }
              }

              &.image-column {
                width: 75px;
                height: 75px;
              }

              &.article-column {
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              &.position-column,
              &.quantity-column,
              &.price-per-unit-column {
                font-weight: bold;
                text-align: center;
              }
              &.price-column {
                font-weight: bold;
                text-align: right;
                padding-right: 20px;
              }

              &.quantity-column {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .recom-input {
                  margin-right: 12px;
                  text-align: right;
                  font-size: var(--font16);
                  &.active {
                    color: var(--active-color);
                    border-bottom: 2px solid;
                  }
                  input {
                    min-width: 20px;
                    width: 20px;
                    padding: 0;
                    text-align: right;
                    margin-left: auto;
                    border-bottom: none;
                    color: inherit;
                  }
                }

                .setting-control {
                  height: 62px;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  &:not(.disabled) > .setting-control-item {
                    cursor: pointer;
                    svg > g {
                      fill: var(--dark_grey-color);
                    }
                  }
                }
              }
            }
          }

          .no-data {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;
            height: 100%;
            padding: 8px;

            font-size: large;
            font-weight: bold;
          }
        }

        .bom-filter {
          padding: 16px;
          background: #052334;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;

          .label {
            color: var(--white-color);
            padding-right: 4px;
          }

          input {
            width: 196px;
            text-align: left;
          }

          .reset-button {
            margin: 4px;
            cursor: pointer;

            &.disabled {
              cursor: default;
            }

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      .window-popup-footer {
        flex: 0 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;

        .left-controls {
          flex-basis: 75%;
          display: grid;
          grid-template-columns: 1.5fr repeat(2, 2fr);
          grid-gap: 12px;

          .separator {
            grid-column: 1/-1;
            border-bottom: solid 1px #657d48;
          }
        }

        .right-controls {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          padding: 4px 4px 16px 4px;

          font-weight: bold;

          .price-total {
            flex: 0 0;
            .price {
              font-size: 1rem;
              vertical-align: middle;
            }
          }

          .customer-price-toggle {
            display: flex;
            align-items: center;
            overflow-wrap: break-word;
            padding: 20px 0;
            max-width: 150px;
            flex: auto;
            column-gap: 6px;
          }

          .buy-wrap {
            flex: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 12px;
            .buy {
              text-align: right;
            }
          }
        }

        .button-item {
          display: inline-flex;
          text-align: left;
          align-items: center;
          font-size: var(--font12);
          color: var(--white-color);
          letter-spacing: 0.12px;
          white-space: pre-wrap;

          &:disabled {
            color: var(--grey-color);
            .cls-1 {
              fill: var(--grey-color);
            }
          }

          &.left {
            i {
              margin-right: 8px;
            }
          }

          &.right {
            flex-direction: row-reverse;
            i {
              margin-left: 8px;
            }
          }
        }
      }
    }
    .calculation {
      width: 414px;
      text-align: center;
      margin-top: -20px;
      .calculation-icon {
        padding: 16px 0;
      }
      .calculation-text {
        font-weight: bold;
        font-size: var(--font16);
      }
    }
    .draw-free {
      width: 380px;
      .draw-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .dimension-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 24px;
          .draw-icon {
            margin-right: 15px;
          }
          .dimension-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            &:not(:last-child) {
              margin-bottom: 15px;
            }
            input {
              margin-left: auto;
              margin-right: 5px;
              color: var(--dark_grey-color);
              font-weight: inherit;
              border-bottom: 1.1px solid;
              width: 45px;
            }
          }
        }
      }
    }
    .error {
      .window-popup-content {
        width: 300px;
        min-height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--font16);
        line-height: 1.14;
      }
    }
    .window-dialog {
      width: 414px;
      .window-popup-header {
        text-align: center;
        text-transform: uppercase;
        line-height: 1.3em;
      }
      .dialog-text {
        line-height: 1.3em;
        font-size: var(--font16);
        text-align: center;
        margin-bottom: 28px;
        // margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        text-align: center;
        font-weight: bold;
        white-space: pre-wrap;
        .dialog-image {
          margin-right: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .dialog-image + .dialog-message {
          font-weight: normal;
          text-align: left;
          overflow: hidden;
          letter-spacing: normal;
          a {
            max-width: 100%;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            vertical-align: bottom;
            font-weight: bold;
            text-decoration: underline;
            white-space: nowrap;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
      .dialog-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        .ok {
          font-weight: bold;
          border-width: 2px;
        }
      }
      .finish-planning-header {
        border-top: 2px dotted var(--active-color);
        padding-top: 16px;
        margin-top: 16px;
      }
    }
    .login {
      width: 414px;
      .window-popup-header {
        text-align: center;
      }
      .window-popup-content {
        padding: 33px 24px 43px 24px;

        .login-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .login-text {
            margin-left: 22px;
            font-weight: bold;
            font-size: var(--font16);
            line-height: 21px;
            a {
              color: var(--active-color);
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .about-water-supply-popup {
      position: relative;
      border-radius: 8px;
      background-image: linear-gradient(to bottom, #151517, #052334);
      .window-popup-content {
        padding: 37px 10px 11px 10px;
      }
      .window-popup-close {
        color: var(--white-color2);
      }
    }
    .bom-type {
      font-family: var(--font-text);

      .window-popup-header {
        text-align: center;
      }

      .window-popup-content {
        position: relative;
        max-height: min(calc(100vh - 220px), 621px);

        &::before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 230px;
          opacity: 0.56;
          background-image: linear-gradient(to bottom, #529f40, #c5d75d);
          border-radius: 100% 0 0 0;
          width: calc(100% + 150px);
        }
      }

      .bom-type-content {
        position: relative;
        z-index: 2;

        .footer-wrapper {
          padding-bottom: 6px;

          .customer-buttons-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;

            background: #ccd790;
            border-radius: 16px;
            padding: 12px 9px;
            box-shadow: 0 2px 6px 0 rgba(5, 35, 52, 0.25);

            button:focus {
              --active-color: var(--red-color);
            }
            button:first-of-type {
              margin-bottom: 4px;
            }

            .buttons-description {
              padding-bottom: 8px;
            }
          }
        }
      }
      .bom-type-list {
        display: grid;
        grid-template-columns: repeat(3, minmax(auto, 200px));
        gap: min(3vw, 43px);
        padding: 0 46px 8px 46px;
        align-items: end;
        position: relative;

        .item {
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
          border-radius: 16px;
          overflow: hidden;

          &.item-blurred {
            opacity: 0.4;
            filter: blur(10px);
          }

          .header {
            position: relative;
            overflow: hidden;

            &:before {
              content: "";
              display: block;
              position: absolute;
              left: 10px;
              bottom: 0;

              width: 0;
              height: 0;
              background: none;

              box-shadow: 0 0 63px 33px var(--shadow-color);
            }
            padding: 12px;
            background: #151517;
            text-align: center;

            .title {
              font-size: 24px;
              font-weight: bold;
              font-stretch: condensed;
              font-style: normal;
              line-height: 1.21;
              letter-spacing: 1.2px;
              color: var(--title-color);
            }
            .subtitle {
              font-size: 14px;
              font-weight: normal;
              font-stretch: condensed;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              color: #c5d75d;
            }
          }

          .content {
            border-left: solid 3px #c5d75d;
            border-right: solid 3px #c5d75d;
            background-color: #c5d75d;

            font-size: 14px;
            font-weight: normal;
            font-stretch: condensed;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: left;

            .price-wrapper {
              color: #052334;
              padding: 12px 9px 12px 9px;
              background: #fff;
              border-radius: 2px;

              .price {
                font-size: 36px;
                font-weight: bold;
                font-stretch: condensed;
                font-style: normal;
                line-height: 1.22;
                letter-spacing: normal;
                text-align: center;
              }

              .description {
                font-size: 16px;
                font-weight: bold;
                font-stretch: condensed;
                font-style: normal;
                line-height: 1.19;
                letter-spacing: normal;
                text-align: center;
              }

              .price + .description {
                margin-top: 13px;
              }
            }

            .advantages {
              color: #3e4346;
              padding: 12px 9px 12px 9px;
              li::before {
                content: "✔︎ ";
                display: inline;
              }
              li + li {
                margin-top: 4px;
              }
            }

            .button-wrapper {
              padding: 12px 9px 12px 9px;

              display: flex;
              justify-content: center;
              .button {
                border: solid 1px #f5f5f5;
                font-size: 14px;

                &:active,
                &:focus {
                  background: #f5f5f5;
                }
              }
            }
          }
        }

        .eco.item {
          --shadow-color: #96c255;
          --title-color: #96c255;
          .advantages {
            min-height: 190px;
          }
        }
        .expert.item {
          --shadow-color: #878787;
          --title-color: #878787;

          .advantages {
            min-height: 190px;
          }
        }
        .premium.item {
          --shadow-color: #c33546;
          --title-color: #c33546;
          .advantages {
            min-height: 190px;
          }
        }
      }
    }
    .tube-type {
      .type-list-title {
        font-size: 1rem;
        font-weight: bold;
        font-stretch: condensed;
        line-height: 1.31;
        letter-spacing: 0.4px;
        margin-bottom: 12px;
      }

      .type-item {
        .radio-wrap {
          font-size: 0.875rem;
          font-weight: normal;
          font-stretch: condensed;
          line-height: 1.14;
          letter-spacing: 0.35px;
          b {
            font-weight: bold;
          }
        }
      }
      .type-item + .type-item {
        margin-top: 12px;
      }

      .type-list + .dialog-text {
        border-top: dotted 2px #c5d75d;
        margin-top: 14px;
        padding-top: 14px;
      }

      .dialog-text {
        .dialog-message {
          text-align: left;
        }
      }
    }
    .bom-recalc-type {
      .type-list-title {
        font-size: 1rem;
        font-weight: bold;
        font-stretch: condensed;
        line-height: 1.31;
        letter-spacing: 0.4px;
        margin-bottom: 12px;
      }

      .type-item {
        .radio-wrap {
          font-size: 0.875rem;
          font-weight: normal;
          font-stretch: condensed;
          line-height: 1.14;
          letter-spacing: 0.35px;
          b {
            font-weight: bold;
          }
        }
      }
      .type-item + .type-item {
        margin-top: 12px;
      }

      .type-list + .dialog-text {
        border-top: dotted 2px #c5d75d;
        margin-top: 14px;
        padding-top: 14px;
      }

      .dialog-text {
        .dialog-message {
          text-align: left;
        }
      }
    }
  }

  .sprinkler {
    user-select: none;
    cursor: pointer;

    &.active.invalid,
    &.invalid.hovered,
    &.invalid {
      .size {
        fill: rgb(255, 118, 0);
        fill-opacity: 0.05;
      }
      .sector,
      .rect {
        fill: rgb(255, 118, 0);
        fill-opacity: 0.3;
      }
      .sprinkler-info {
        fill: var(--red-color);
      }
    }
    .size {
      display: none;
    }
    .sector {
      stroke: var(--dark_grey-color);
      fill: rgb(0, 196, 255);
      fill-opacity: 0.15;
      .sector-start {
        display: none;
        fill: var(--dark_grey-color);
        stroke: var(--dark_grey-color);
      }
    }
    .sector.nofill {
      fill: rgba(0, 196, 255, 0);
    }
    .sprinkler-close {
      display: none;
    }
    .sprinkler-info {
      line-height: 1.25;
      text-align: left;
      fill: var(--dark_grey-color);
      .sprinkler-info-title {
        font-weight: bold;
      }
      .hide-info {
        display: none;
      }
      tspan.star {
        display: block;
      }
    }
    .rect {
      fill: rgb(0, 196, 255);
      fill-opacity: 0.15;
      stroke: var(--dark_grey-color);
    }
    .rect.nofill {
      fill: rgb(0, 196, 255);
      fill-opacity: 0;
    }
    .element-hidden {
      .sector,
      .rect {
        fill-opacity: 0;
      }
    }
    .close {
      .cls-1 {
        fill: var(--red-color);
      }

      .cls-2 {
        fill: none;
        stroke: #fff;
        stroke-width: 2px;
      }
    }

    &.active {
      z-index: 2;
    }
    &.active,
    &.hovered {
      .hide-info {
        display: block;
      }
      tspan.star {
        display: none;
      }
      .size {
        display: block;
        fill: rgb(0, 196, 255);
        fill-opacity: 0.05;
        stroke-dasharray: none;
        stroke: var(--dark_grey-color);
      }
      .sector {
        fill: rgb(0, 196, 255);
        fill-opacity: 0.3;
        .sector-start {
          display: block;
        }
      }
      .rect {
        fill: rgb(0, 196, 255);
        fill-opacity: 0.4;
      }
      .sprinkler-border {
        stroke-width: 1.7;
      }
    }

    &.active {
      .sprinkler-close {
        display: block;
      }
    }
  }

  .element {
    user-select: none;
    cursor: pointer;
    .control:hover,
    .control.active,
    .area-control-item:hover,
    .area-control-item.active {
      fill: var(--dark_grey-color);
    }

    .control:hover,
    .control.active,
    .area-control-rotate-item:hover,
    .area-control-rotate-item.active {
      .rotate-circle {
        fill: var(--dark_grey-color);
      }
      .rotate-arrow {
        fill: #fff;
      }
    }
  }

  .system-element {
    cursor: pointer;
  }

  .control {
    fill: var(--white-color);
    &.active {
      fill: var(--dark_grey-color);
    }
  }

  .addable {
    cursor: crosshair !important;
  }

  .background-move {
    cursor: pointer !important;
  }

  .pipe-point {
    .pipe-startpoint-elem,
    .pipe-drippoint-elem {
      .stroke-point {
        display: none;
      }
    }
    .pipe-point-elem {
      cursor: pointer;
    }
    .pipe-point-elem > .pipe-point-bg-elem {
      display: none;
    }

    &.active {
      cursor: pointer;

      .pipe-startpoint-elem,
      .pipe-drippoint-elem {
        .stroke-point {
          display: inline-block;
        }
      }

      .pipe-point-elem > .pipe-point-bg-elem {
        display: inline-block;
      }

      .pipe-point-bp-bg-elem {
        opacity: 1;
      }
    }

    .pipe-point-bp-bg-elem {
      opacity: 0;
      &:hover {
        opacity: 1;
      }
    }
  }

  .pipe-line {
    .pipe-line-bg-elem {
      display: none;
    }

    &.active {
      cursor: pointer;
      .pipe-line-bg-elem {
        display: inline-block !important;
      }
    }
  }

  .element-hidden {
    stroke-opacity: 0.25;
    fill-opacity: 0.25;
    cursor: url("../../assets/cursor-nogo.svg") 7 7, not-allowed !important;
    .addable {
      cursor: url("../../assets/cursor-nogo.svg") 7 7, not-allowed !important;
    }
  }

  @media (hover) {
    .pipe-point {
      &:not(.disabled):hover {
        cursor: pointer;
        .pipe-startpoint-elem,
        .pipe-drippoint-elem {
          .stroke-point {
            display: inline-block;
          }
        }
        .pipe-point-elem > .pipe-point-bg-elem {
          display: inline-block;
        }
        .pipe-point-bp-bg-elem {
          opacity: 1;
        }
      }
    }

    .pipe-line {
      &:not(.disabled):hover {
        cursor: pointer;
        .pipe-line-bg-elem {
          display: inline-block !important;
        }
      }
    }

    .trench-info {
      &:hover {
        cursor: pointer;
        fill: #c5d75d;
      }
    }
  }

  @media only screen and (max-width: 1280px) {
    header {
      padding-top: 14px;
      min-height: 80px;
      .logo {
        margin-top: 0;
      }
      .steps {
        order: 3;
        width: 100%;
        height: 63px;
      }
      .right {
        margin-left: auto;
      }
    }
  }
}
